import React from "react";
import { Link } from "gatsby";
import { Carousel, Grid } from "antd";

export const HomeBanner = (props) => {
  const contentStyle = {
    width: "100%",
    "object-fit": "cover",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };

  return (
    <Carousel
      autoplay="true"
      autoplaySpeed={4000}
      speed={1000}
      pauseOnHover={false}>
      <div>
        <div className="tw-hidden md:tw-block">
          {props.lang === "en" && (
            <Link to="/member-benefits">
              <img
                src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/AR_Website_Hero_Banner_1_3720_X_892_01_en_66ab835d23.jpg"
                alt="Pay with Points"
                style={contentStyle}
              />
            </Link>
          )}
          {props.lang === "ja" && (
            <Link to="/ja/member-benefits">
              <img
                src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/AR_WEBSITE_BANNER_JAP_Hero_Banner_1_3720_X_892_01_274e3a2e53.jpg"
                alt="Pay with Points"
                style={contentStyle}
              />
            </Link>
          )}
          {props.lang === "zh" && (
            <Link to="/zh/member-benefits">
              <img
                src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/AR_WEBSITE_BANNER_CHI_Hero_Banner_1_3720_X_892_01_72c37eba41.jpg"
                alt="Pay with Points"
                style={contentStyle}
              />
            </Link>
          )}
        </div>
        <div className="tw-block md:tw-hidden">
          {props.lang === "en" && (
            <Link to="/member-benefits">
              <img
                src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/AR_Website_Hero_Banner_1_750x840_01_725939eeda.jpg"
                alt="Pay with Points"
                style={contentStyle}
              />
            </Link>
          )}
          {props.lang === "ja" && (
            <Link to="/ja/member-benefits">
              <img
                src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/AR_WEBSITE_BANNER_JAP_Hero_Banner_1_750x840_01_b061c7bbd3.jpg"
                alt="Pay with Points"
                style={contentStyle}
              />
            </Link>
          )}
          {props.lang === "zh" && (
            <Link to="/zh/member-benefits">
              <img
                src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/AR_WEBSITE_BANNER_CHI_Hero_Banner_1_750x840_01_49790a6785.jpg"
                alt="Pay with Points"
                style={contentStyle}
              />
            </Link>
          )}
        </div>
      </div>
      <div>
        <div className="tw-hidden md:tw-block">
          {props.lang === "en" && (
            <Link to="/promotion/enjoy-an-exclusive-membership-discount-at-all-ayana-properties">
              <img
                src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/AR_WEBSITE_BANNER_ENG_Hero_Banner_2_3720x892_02_93f9bd6b96.jpg"
                alt="Pay with Points"
                style={contentStyle}
              />
            </Link>
          )}
          {props.lang === "ja" && (
            <Link to="/ja/promotion/enjoy-an-exclusive-membership-discount-at-all-ayana-properties">
              <img
                src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/AR_WEBSITE_BANNER_JAP_Hero_Banner_2_3720x892_02_f3377f7281.jpg"
                alt="Pay with Points"
                style={contentStyle}
              />
            </Link>
          )}
          {props.lang === "zh" && (
            <Link to="/zh/promotion/enjoy-an-exclusive-membership-discount-at-all-ayana-properties">
              <img
                src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/AR_WEBSITE_BANNER_CHI_Hero_Banner_2_3720x892_02_65976140fd.jpg"
                alt="Pay with Points"
                style={contentStyle}
              />
            </Link>
          )}
        </div>
        <div className="tw-block md:tw-hidden">
          {props.lang === "en" && (
            <Link to="/promotion/enjoy-an-exclusive-membership-discount-at-all-ayana-properties">
              <img
                src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/AR_WEBSITE_BANNER_ENG_Hero_Banner_2_750x840_02_6523909b0f.jpg"
                alt="Pay with Points"
                style={contentStyle}
              />
            </Link>
          )}
          {props.lang === "ja" && (
            <Link to="/ja/promotion/enjoy-an-exclusive-membership-discount-at-all-ayana-properties">
              <img
                src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/AR_WEBSITE_BANNER_JAP_Hero_Banner_2_750x840_02_57c71fbdf6.jpg"
                alt="Pay with Points"
                style={contentStyle}
              />
            </Link>
          )}
          {props.lang === "zh" && (
            <Link to="/zh/promotion/enjoy-an-exclusive-membership-discount-at-all-ayana-properties">
              <img
                src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/AR_WEBSITE_BANNER_ZH_Hero_Banner_2_750x840_02_be80affda3.jpg"
                alt="Pay with Points"
                style={contentStyle}
              />
            </Link>
          )}
        </div>
      </div>
      <div>
        <div className="tw-hidden md:tw-block">
          {props.lang === "en" && (
            <Link to="/pay-with-points">
              <img
                src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/D_Pay_with_points_EN_e8b7eb1489.png"
                alt="Pay with Points"
                style={contentStyle}
              />
            </Link>
          )}
          {props.lang === "ja" && (
            <Link to="/ja/pay-with-points">
              <img
                src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/D_Pay_with_points_JA_85257caf97.png"
                alt="Pay with Points"
                style={contentStyle}
              />
            </Link>
          )}
          {props.lang === "zh" && (
            <Link to="/zh/pay-with-points">
              <img
                src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/D_Pay_with_points_ZH_3a7b673eeb.png"
                alt="Pay with Points"
                style={contentStyle}
              />
            </Link>
          )}
        </div>
        <div className="tw-block md:tw-hidden">
          {props.lang === "en" && (
            <Link to="/pay-with-points">
              <img
                src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/M_Pay_with_points_EN_b780f54422.png"
                alt="Pay with Points"
                style={contentStyle}
              />
            </Link>
          )}
          {props.lang === "ja" && (
            <Link to="/ja/pay-with-points">
              <img
                src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/M_Pay_with_points_JA_3c5c231dd7.png"
                alt="Pay with Points"
                style={contentStyle}
              />
            </Link>
          )}
          {props.lang === "zh" && (
            <Link to="/zh/pay-with-points">
              <img
                src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/M_Pay_with_points_ZH_95272b9a0a.png"
                alt="Pay with Points"
                style={contentStyle}
              />
            </Link>
          )}
        </div>
      </div>
    </Carousel>
  );
};
